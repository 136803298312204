import React from "react";
function Scoreboard() {
  

  return (
    <div className="scoreboard container">
      <div className="heading">
        <h1>
          HACK HUNTER
          <span>The Cyber Battle Arena</span>
        </h1>
      </div>
      <div className="sub_hw">&mdash; Scoreboard</div>
      <div style={{ height: "400px", width: "600px" }}>
      </div>
    </div>
  );
}

export default Scoreboard;
